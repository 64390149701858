import React from "react"

export function Stat({ icon, title, text }) {
  return (
    <li className="flex flex-col gap-6 rounded-2xl justify-center text-center items-center bg-white bg-opacity-5 text-white h-60">
      <div className="flex justify-center items-center ">{icon}</div>
      <div className="flex justify-center items-center flex-col gap-1">
        <h4 className="text-sm md:text-base leading-6 font-bold text-white">
          {title}
        </h4>
        <p className="text-sm leading-6 font-medium text-white w-2/3 text-opacity-60">
          {text}
        </p>
      </div>
    </li>
  )
}

import React from "react"
import { Menu } from "@headlessui/react"
import { CheckCircleIcon, ChevronDownIcon } from "@heroicons/react/outline"

function SiteListbox({
  stationsList,
  setContextState,
  selectedSpot,
  setSelectedSpot,
  selectedSpotName,
  setSelectedSpotName,
}) {
  let sites = stationsList[0].sites.concat(stationsList[1].sites)

  const onClickHandler = (site, spot) => {
    setContextState(site)
    setSelectedSpot(spot)
    setSelectedSpotName(spot.city + "(" + spot.code + ")")
  }

  return (
    <Menu className="relative" as="div">
      <Menu.Button className="relative group text-2xl flex items-center gap-2 uppercase font-bold self-start ">
        <span className="group-hover:text-opacity-50">
          {selectedSpot.country}
        </span>
        <ChevronDownIcon className="group-hover:text-orbitalAccent h-7" />
      </Menu.Button>
      <Menu.Items className="absolute z-50 p-8 w-72 rounded-lg bg-orbitalBlack flex flex-col gap-2">
        {sites
          .sort((a, b) => a.country.localeCompare(b.country))
          .map((site, siteIdx) => (
            /* Use the `active` state to conditionally style the active item. */
            <Menu.Item key={siteIdx}>
              <button
                onClick={() => onClickHandler(site, site.spots[0])}
                className={`opacity-90 hover:opacity-100 text-left text-base flex justify-between items-center uppercase${
                  site.country === selectedSpot.country
                    ? " text-white opacity-100"
                    : " text-white"
                }${!site.active && " text-opacity-50 cursor-not-allowed "}`}
                disabled={!site.active}
              >
                {site.country}
                {site.country === selectedSpot.country && (
                  <CheckCircleIcon className="h-6 text-white" />
                )}
                {!site.active && (
                  <p className="text-sm text-opacity-100">COMING SOON</p>
                )}
              </button>
            </Menu.Item>
          ))}
      </Menu.Items>
    </Menu>
  )
}

export default SiteListbox

import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import SiteListbox from "./SiteListbox"
import SpotSectionTitle from "./SpotSectionTitle"

const SiteName = ({
  site,
  stationsList,
  setContextState,
  setSelectedSpot,
  selectedSpotName,
  setSelectedSpotName,
}) => (
  <div className="flex flex-col w-full text-3xl uppercase">
    <SiteListbox
      stationsList={stationsList}
      setContextState={setContextState}
      selectedSpot={site}
      setSelectedSpot={setSelectedSpot}
      selectedSpotName={selectedSpotName}
      setSelectedSpotName={setSelectedSpotName}
    />
  </div>
)

const SiteInfo = ({ selectedSpot }) => (
  <div className="grid grid-cols-6 gap-6 justify-between text-xs">
    <div className="flex flex-col w-full col-span-full md:col-span-3 gap-2 text-white">
      <div className="text-sm font-semibold uppercase text-orbitalAccent ">
        Location
      </div>
      <div className="text-gray-50 text-opacity-70">{selectedSpot.address}</div>
    </div>
    <div className="flex flex-col w-full col-span-full md:col-span-3 gap-2 text-white">
      <div className="text-sm font-semibold uppercase text-orbitalAccent ">
        Coordinates
      </div>
      <div className="flex 2xl:flex-row gap-2 text-gray-50 text-opacity-70">
        <p>LAT: {selectedSpot.latitude}</p>
        <p>LON: {selectedSpot.longitude}</p>
      </div>
    </div>
    <div className="flex flex-col w-full col-span-full md:col-span-2 gap-2 text-white">
      <div className="text-sm font-semibold uppercase text-orbitalAccent ">
        Distance to airport
      </div>
      <div className="font-thin text-sm text-gray-50 text-opacity-70">
        {selectedSpot.distanceToAirport} {"kilometers"}
      </div>
    </div>
    <div className="flex gap-4 text-xs col-span-full md:col-span-4 justify-around md:justify-end items-center">
      <a
        href={selectedSpot.googleMapsLink}
        target="_blank"
        rel="noreferrer"
        className="px-3 py-2 border hover:border-orbitalSecondary rounded-full uppercase transition-all duration-500"
      >
        Open in Google maps
      </a>
      <AnchorLink
        to="/#contact-us"
        className="px-3 py-2 hover:bg-opacity-50 bg-orbitalPrimary rounded-full uppercase transition-all duration-500"
      >
        CONTACT US
      </AnchorLink>
    </div>
  </div>
)

const SiteAvatar = ({ image }) => {
  return (
    <img
      className="w-40 h-28 rounded-lg justify-center items-center flex"
      src={image}
    />
  )
}

export const SiteBadge = ({
  site,
  selectedSpot,
  setSelectedSpot,
  stationsList,
  setContextState,
  selectedSpotName,
  setSelectedSpotName,
}) => {
  const onChangeHandler = spot => {
    setSelectedSpot(spot)
    setSelectedSpotName(spot.city + "(" + spot.code + ")")
  }
  return (
    <div className="px-4 md:px-0 flex flex-col gap-8">
      <SpotSectionTitle />
      <div className="flex flex-col bg-white bg-opacity-5 p-4 md:p-8 gap-8 rounded-lg">
        <div className="flex gap-4 w-full justify-between">
          <SiteName
            site={site}
            stationsList={stationsList}
            setSelectedSpot={setSelectedSpot}
            setContextState={setContextState}
            selectedSpot={selectedSpot}
            selectedSpotName={selectedSpotName}
            setSelectedSpotName={setSelectedSpotName}
          />
        </div>
        {site.spots ? (
          <ul className="grid grid-cols-2 gap-4 justify-between w-full">
            {site.spots?.map(spot => (
              <li key={spot.code}>
                <h5
                  className={`flex flex-col gap-2 text-xs md:text-lg hover:text-orbitalAccent font-bold opacity-60 hover:opacity-100 cursor-pointer transition-all duration-200 ${
                    spot.code === selectedSpot.code &&
                    " text-orbitalAccent opacity-100 "
                  }`}
                  onClick={() => onChangeHandler(spot)}
                >
                  {spot.city} ({spot.code})
                </h5>
              </li>
            ))}
          </ul>
        ) : null}
        <div className="h-0.5 rounded-full w-full bg-white opacity-50" />
        <SiteInfo selectedSpot={selectedSpot} />
      </div>
    </div>
  )
}

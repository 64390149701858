import React, { useContext, useState } from "react"
import { Menu } from "@headlessui/react"
import { CheckCircleIcon, ChevronDownIcon } from "@heroicons/react/outline"

import ReCAPTCHA from "react-google-recaptcha"
import contactBg from "../images/image-antenna.png"
import { AppContext } from "./Provider"
import axios from "axios"
import Modal from "./Modal"

const spotsList = [
  {
    code: "LIM",
    city: "Lima",
    address: "Carlos Krumdieck 287",
    googleMapsLink:
      "https://www.google.com.uy/maps/place/33%C2%B043'52.5%22S+56%C2%B019'07.2%22W/@-33.731236,-56.318676,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xdd59be00dd0d09be!8m2!3d-33.731236!4d-56.318676",
    distanceToAirport: "8,0",
    latitude: -12.0869033,
    longitude: -77.0126569,
    geometry: {
      type: "Point",
      coordinates: [-12.0869033, -77.0126569],
    },
  },
  {
    code: "UIO",
    city: "Quito",
    address: "Av. Amazonas 4545 y Pereira Edif. Centro Financiero",
    googleMapsLink:
      "https://www.google.com.uy/maps/place/33%C2%B043'52.5%22S+56%C2%B019'07.2%22W/@-33.731236,-56.318676,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xdd59be00dd0d09be!8m2!3d-33.731236!4d-56.318676",
    distanceToAirport: "10,0",
    latitude: -0.1711531,
    longitude: -78.4849534,
    geometry: { type: "Point", coordinates: [-0.1711531, -78.4849534] },
  },
  {
    code: "UY",
    city: "Montevideo",
    address: "San Luis 7089.",
    googleMapsLink:
      "https://www.google.com.uy/maps/place/33%C2%B043'52.5%22S+56%C2%B019'07.2%22W/@-33.731236,-56.318676,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xdd59be00dd0d09be!8m2!3d-33.731236!4d-56.318676",
    distanceToAirport: "4,17",
    latitude: -34.8727058,
    longitude: -56.0300716,
    geometry: {
      type: "Point",
      coordinates: [-34.8727058, -56.0300716],
    },
  },
  {
    code: "UY2",
    city: "Florida",
    address: "Sarandí Grande.",
    googleMapsLink:
      "https://www.google.com.uy/maps/place/33%C2%B043'52.5%22S+56%C2%B019'07.2%22W/@-33.731236,-56.318676,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xdd59be00dd0d09be!8m2!3d-33.731236!4d-56.318676",
    distanceToAirport: "126",
    latitude: -33.731236,
    longitude: -56.318676,
    geometry: { type: "Point", coordinates: [-33.731236, -56.318676] },
  },
  {
    code: "BOG",
    city: "Bogotá",
    address: "San Luis 7089.",
    googleMapsLink:
      "https://www.google.com.uy/maps/place/33%C2%B043'52.5%22S+56%C2%B019'07.2%22W/@-33.731236,-56.318676,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xdd59be00dd0d09be!8m2!3d-33.731236!4d-56.318676",
    distanceToAirport: "4,17",
    latitude: 4.6728805,
    longitude: -74.0497312,
    geometry: {
      type: "Point",
      coordinates: [4.6728805, -74.0497312],
    },
  },
  {
    code: "GDL",
    city: "Guadalajara",
    address:
      "Av. Chapultepec 236 Col. Americana. Guadalajara, Jalisco CP 44180.",
    googleMapsLink:
      "https://www.google.com/maps/place/20%C2%B040'21.5%22N+103%C2%B022'08.6%22W/@20.6726497,-103.3778175,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfc8d331ba6f7c1a3!8m2!3d20.6726298!4d-103.3690627",
    distanceToAirport: "11,2",
    latitude: 20.6726298,
    longitude: -103.3690627,
    geometry: {
      type: "Point",
      coordinates: [20.6726298, -103.3690627],
    },
  },
  {
    code: "AGU",
    city: "Aguascalientes",
    address:
      "Av. Francisco I Madero No. 724 Col. Centro de arriba Aguascalientes, AGS. CP. 20000.",
    googleMapsLink:
      "https://www.google.com/maps/place/21%C2%B053'05.2%22N+102%C2%B016'59.7%22W/@21.884773,-102.2854427,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xeb41eb6660b12d1!8m2!3d21.884773!4d-102.283254",
    distanceToAirport: "20,7",
    latitude: 21.884773,
    longitude: -102.283254,
    geometry: {
      type: "Point",
      coordinates: [21.884773, -102.283254],
    },
  },
  {
    code: "CYW",
    city: "Celaya",
    address:
      "Camino a San Jose de Guanajuato No. 205 Col. Providencia, Celaya Guanajuato. CP. 38020.",
    googleMapsLink:
      "https://www.google.com/maps/place/20%C2%B031'46.8%22N+100%C2%B049'56.6%22W/@20.529652,-100.8345717,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xddbc00c95ea8f350!8m2!3d20.529652!4d-100.832383",
    distanceToAirport: "5,6",
    latitude: 20.529652,
    longitude: -100.832383,
    geometry: {
      type: "Point",
      coordinates: [20.529652, -100.832383],
    },
  },
  {
    code: "BJX",
    city: "Irapuato",
    address:
      "Ramón Corona No. 345 Col. Santiaguito Irapuato Guanajuato. CP. 36588.",
    googleMapsLink:
      "https://www.google.com/maps/place/20%C2%B040'17.6%22N+101%C2%B020'59.7%22W/@20.671561,-101.3521117,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xd628dce037263c59!8m2!3d20.671561!4d-101.349923",
    distanceToAirport: "3,7",
    latitude: 20.671561,
    longitude: -101.349923,
    geometry: {
      type: "Point",
      coordinates: [20.671561, -101.349923],
    },
  },
  {
    code: "BJX2",
    city: "Leon",
    address:
      "Rio Guanajuato No. 100-A Col. San Miguel Parte Sur, León Guanajuato. CP. 37460.",
    googleMapsLink:
      "https://www.google.com/maps/place/21%C2%B005'53.9%22N+101%C2%B040'44.5%22W/@21.098318,-101.6812037,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x898d0d896b1c5f4f!8m2!3d21.098318!4d-101.679015",
    distanceToAirport: "23,6",
    latitude: 21.098318,
    longitude: -101.679015,
    geometry: {
      type: "Point",
      coordinates: [21.098318, , -101.679015],
    },
  },
  {
    code: "MEX",
    city: "México City",
    address:
      "Poniente 122 No. 551- A Col. Industrial Vallejo, alcaldía Azcapotzalco, CDMX CP. 02300.",
    googleMapsLink:
      "https://www.google.com/maps/place/19%C2%B027'04.4%22N+99%C2%B013'01.9%22W/@19.451216,-99.2193857,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xbed6455803901476!8m2!3d19.451216!4d-99.217197",
    distanceToAirport: "10,2",
    latitude: 19.486549,
    longitude: -99.15748,
    geometry: { type: "Point", coordinates: [19.486549, -99.15748] },
  },
  {
    code: "MEX2",
    city: "México City",
    address:
      "Rodolfo Gaona No. 86 Col. Lomas de Sotelo, alcaldía Miguel Hidalgo, CDMX CP. 11200.",
    googleMapsLink:
      "https://www.google.com/maps/place/19%C2%B027'04.4%22N+99%C2%B013'01.9%22W/@19.451216,-99.2193857,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xbed6455803901476!8m2!3d19.451216!4d-99.217197",
    distanceToAirport: "14,0",
    latitude: 19.451216,
    longitude: -99.217197,
    geometry: { type: "Point", coordinates: [19.451216, -99.217197] },
  },
  {
    code: "MTY",
    city: "Monterrey",
    address:
      "Av. San Jerónimo Poniente No. 120, Col. San Jerónimo Monterrey Nuevo León CP. 64640.",
    googleMapsLink:
      "https://www.google.com/maps/place/25%C2%B040'24.1%22N+100%C2%B021'17.8%22W/@25.673346,-100.3571197,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xa0649fb01ce13958!8m2!3d25.673346!4d-100.354931",
    distanceToAirport: "26,0",
    latitude: 25.673346,
    longitude: -100.354931,
    geometry: { type: "Point", coordinates: [25.673346, -100.354931] },
  },
  {
    code: "MTY2",
    city: "Monterrey",
    address:
      "15 de Mayo Oriente S/N Col. Centro Monterrey Nuevo León. CP. 64000.",
    googleMapsLink:
      "https://www.google.com/maps/place/25%C2%B040'18.5%22N+100%C2%B018'44.5%22W/@25.671794,-100.3145447,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xd4c7a2173b849924!8m2!3d25.671794!4d-100.312356",
    distanceToAirport: "23,0",
    latitude: 25.671794,
    longitude: -100.312356,
    geometry: { type: "Point", coordinates: [25.671794, -100.312356] },
  },
  {
    code: "QRO",
    city: "Querétaro",
    address:
      "San Roque No. 229 Col. Los Eucaliptos, Querétaro, Querétaro CP. 76156.",
    googleMapsLink:
      "https://www.google.com/maps/place/20%C2%B036'13.3%22N+100%C2%B024'22.6%22W/@20.603696,-100.4084757,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x53e4d6075cf5aef0!8m2!3d20.603696!4d-100.406287",
    distanceToAirport: "22,5",
    latitude: 20.603696,
    longitude: -100.406287,
    geometry: { type: "Point", coordinates: [20.603696, -100.406287] },
  },
  {
    code: "REX",
    city: "Reynosa",
    address:
      "Blvd Beethoven No. 205 Col. Narciso Mendoza, Raynosa, Tamaulipas CP. 88700.",
    googleMapsLink:
      "https://www.google.com/maps/place/26%C2%B004'33.2%22N+98%C2%B018'36.1%22W/@26.075887,-98.3122237,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x8cf5531f38af30b2!8m2!3d26.075887!4d-98.310035",
    distanceToAirport: "10,8",
    latitude: 26.075887,
    longitude: -98.310035,
    geometry: { type: "Point", coordinates: [26.075887, -98.310035] },
  },
  {
    code: "SLW",
    city: "Saltillo",
    address:
      "Fernando Duran No. 840 Col. Los Maestros, Santillo Coahuila CP. 25260.",
    googleMapsLink:
      "https://www.google.com/maps/place/25%C2%B026'51.5%22N+100%C2%B059'50.3%22W/@25.447644,-100.9994927,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x8724b12cd66cf8ef!8m2!3d25.447644!4d-100.997304",
    distanceToAirport: "13",
    latitude: 25.447644,
    longitude: -100.997304,
    geometry: { type: "Point", coordinates: [25.447644, -100.997304] },
  },
  {
    code: "SLP",
    city: "San Luis Potosí",
    address:
      "Av. Muñoz No. 405 Col. Tequisquiapan, San Luis Potosi, SLP. CP. 78320.",
    googleMapsLink:
      "https://www.google.com/maps/place/22%C2%B009'08.7%22N+100%C2%B059'57.4%22W/@22.152412,-101.0014607,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x1947c08f3970670!8m2!3d22.152412!4d-100.999272",
    distanceToAirport: "13,4",
    latitude: 22.152412,
    longitude: -100.997304,
    geometry: { type: "Point", coordinates: [22.152412, -100.999272] },
  },
  {
    code: "GUA",
    city: "Guatemala City",
    address: "Guatemala City, 13 Calle 15-60.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B035'02.8%22N+90%C2%B031'18.4%22W/@14.5841434,-90.5305237,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0x72832a6b4a626d3b!8m2!3d14.5841228!4d-90.5217689",
    distanceToAirport: "1,0",
    latitude: 14.58412280944792,
    longitude: -90.52176886551757,
    geometry: {
      type: "Point",
      coordinates: [14.58412280944792, -90.52176886551757],
    },
  },
  {
    code: "GUA2",
    city: "Guatemala City",
    address: "10 Avenida 393-369.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B037'18.0%22N+90%C2%B032'41.0%22W/@14.6216692,-90.5469017,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xa3665f7571db18c9!8m2!3d14.621664!4d-90.544713",
    distanceToAirport: "4",
    latitude: 14.621664,
    longitude: -90.544713,
    geometry: {
      type: "Point",
      coordinates: [14.621664, -90.544713],
    },
  },
  {
    code: "GUA3",
    city: "Guatemala City",
    address: "Col La Cañada.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B034'15.1%22N+90%C2%B031'11.5%22W/@14.5708863,-90.5286172,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0xd787c0f1464f6781!8m2!3d14.5708657!4d-90.5198624",
    distanceToAirport: "1",
    latitude: 14.57086573300779,
    longitude: -90.51986243144788,
    geometry: {
      type: "Point",
      coordinates: [14.57086573300779, -90.51986243144788],
    },
  },
  {
    code: "GUA4",
    city: "Guatemala City",
    address: "Edificio CPS, 13 Calle.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B035'03.4%22N+90%C2%B031'19.3%22W/@14.5842905,-90.5307711,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0x8a1998bf4a0907ce!8m2!3d14.5842699!4d-90.5220163",
    distanceToAirport: "1",
    latitude: 14.58426988739851,
    longitude: -90.52201630698464,
    geometry: {
      type: "Point",
      coordinates: [14.58426988739851, -90.52201630698464],
    },
  },
  {
    code: "GUA5",
    city: "Guatemala City",
    address: "Villa Nueva.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B030'19.4%22N+90%C2%B033'57.2%22W/@14.5054039,-90.5746354,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0x96e3e2d009fbf265!8m2!3d14.5053833!4d-90.5658806",
    distanceToAirport: "9,9",
    latitude: 14.50538333333333,
    longitude: -90.56588055555555,
    geometry: {
      type: "Point",
      coordinates: [14.50538333333333, -90.56588055555555],
    },
  },
  {
    code: "GUA6",
    city: "Guatemala City",
    address: "27 Calle 1369-1307.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B037'18.6%22N+90%C2%B030'32.3%22W/@14.6218396,-90.5177369,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0xe13adadb5fcedeac!8m2!3d14.621819!4d-90.5089821",
    distanceToAirport: "4,4",
    latitude: 14.62181903215718,
    longitude: -90.50898205028223,
    geometry: {
      type: "Point",
      coordinates: [14.62181903215718, -90.50898205028223],
    },
  },
  {
    code: "GUA7",
    city: "Guatemala City",
    address: "26 Calle 723-719.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B035'56.3%22N+90%C2%B033'18.9%22W/@14.5989862,-90.5639906,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0xa2994bce82cce37b!8m2!3d14.5989656!4d-90.5552358",
    distanceToAirport: "3",
    latitude: 14.59896560822769,
    longitude: -90.55523578914165,
    geometry: {
      type: "Point",
      coordinates: [14.59896560822769, -90.55523578914165],
    },
  },
  {
    code: "GUA8",
    city: "Guatemala City",
    address: "5 Avenida 17-46.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B034'42.4%22N+90%C2%B031'11.2%22W/@14.5784623,-90.528535,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0x59468c9c87c6de46!8m2!3d14.5784417!4d-90.5197802",
    distanceToAirport: "1,6",
    latitude: 14.57844169143356,
    longitude: -90.5197801962081,
    geometry: {
      type: "Point",
      coordinates: [14.57844169143356, -90.5197801962081],
    },
  },
  {
    code: "GUA9",
    city: "San Jorge Muxba",
    address: "Santa Catarina Pinula.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B031'55.2%22N+90%C2%B028'20.3%22W/@14.5320067,-90.4810715,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0xe1f1f8426ed77ba0!8m2!3d14.5319861!4d-90.4723167",
    distanceToAirport: "8,8",
    latitude: 14.53198611111111,
    longitude: -90.47231666666666,
    geometry: {
      type: "Point",
      coordinates: [14.53198611111111, -90.47231666666666],
    },
  },
  {
    code: "GUA10",
    city: "Villa Canales",
    address: "8va. Calle.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B028'52.5%22N+90%C2%B032'04.2%22W/@14.4812623,-90.5432659,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0xd41715f8ffe1def8!8m2!3d14.4812417!4d-90.5345111",
    distanceToAirport: "11,9",
    latitude: 14.48124166666667,
    longitude: -90.53451111111112,
    geometry: {
      type: "Point",
      coordinates: [114.48124166666667, -90.53451111111112],
    },
  },
  {
    code: "GUA11",
    city: "Guatemala City",
    address: "46 Calle 1806-1948.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B034'08.5%22N+90%C2%B032'58.2%22W/@14.5690278,-90.5495,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x59f15963085b78ed!8m2!3d14.5690167!4d-90.5494971",
    distanceToAirport: "2,9",
    latitude: 14.56901669840475,
    longitude: -90.54949714890785,
    geometry: {
      type: "Point",
      coordinates: [14.56901669840475, -90.54949714890785],
    },
  },
  {
    code: "GUA12",
    city: "Guatemala City",
    address: "2A Avenida.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B036'15.5%22N+90%C2%B031'28.4%22W/@14.6043262,-90.5333104,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0x43dba69b07912588!8m2!3d14.6043154!4d-90.524568",
    distanceToAirport: "1,9",
    latitude: 14.60431538691476,
    longitude: -90.52456795078869,
    geometry: {
      type: "Point",
      coordinates: [14.60431538691476, -90.52456795078869],
    },
  },
  {
    code: "GUA13",
    city: "Guatemala City",
    address: "5A Calle 975-909.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B039'06.0%22N+90%C2%B030'29.8%22W/@14.6516858,-90.5170428,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0x1b81395e42e1a9bc!8m2!3d14.6516652!4d-90.508288",
    distanceToAirport: "7,5",
    latitude: 14.65166519962574,
    longitude: -90.5082880421408,
    geometry: {
      type: "Point",
      coordinates: [14.65166519962574, -90.5082880421408],
    },
  },
  {
    code: "GUA14",
    city: "Guatemala City",
    address: "14 Calle A.",
    googleMapsLink:
      "https://www.google.com/maps/place/14%C2%B039'34.2%22N+90%C2%B027'04.0%22W/@14.6595317,-90.4598653,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0xd9eb8dd71d19a1c5!8m2!3d14.6595111!4d-90.4511105",
    distanceToAirport: "11,7",
    latitude: 114.6595111443794,
    longitude: -90.45111054823802,
    geometry: {
      type: "Point",
      coordinates: [114.6595111443794, -90.45111054823802],
    },
  },
]

export const ContactFormSection = () => {
  const { selectedSpotName, setSelectedSpotName } = useContext(AppContext)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [captchaValue, setCaptchaValue] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()

    if (captchaValue === "") {
      return
    }
    let values = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      company: company,
      phone: phone,
      message: message,
      hearus: selectedSpotName,
      token: captchaValue,
    }

    try {
      const response = await axios.post(
        "https://api.ipxon.net/public/conexum/forms/contact",
        values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      console.log(response)
      setFormSubmitted(true)
    } catch (e) {
      console.log("Error submitting form:", e)
    }
  }

  return (
    <div
      id="contact-us"
      className="w-full py-12 flex justify-center text-white opacity-100 relative z-50"
    >
      <form
        onSubmit={event => handleSubmit(event)}
        className="container mx-auto gap-2 md:gap-16 flex flex-col md:flex-row justify-evenly items-center w-full relative z-50"
      >
        <div className="text-4xl md:w-1/3 items-center justify-center flex flex-col">
          <h4 className="font-bold text-white">Contact us,</h4>
          <h5 className="font-thin text-orbitext">Let's talk about</h5>
        </div>
        <div className="w-full grid grid-cols-4 bg-orbitalBlack p-4 bg-opacity-80 rounded-lg form-control gap-4 flex-grow">
          <div className=" col-span-full md:col-span-2">
            <label className="label">
              <span className="label-text text-white">First name:</span>
            </label>
            <input
              type="text"
              placeholder={"Joe"}
              required={true}
              value={firstName}
              className="input input-accent rounded-full z-40 w-full bg-opacity-30 border-0"
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className=" col-span-full md:col-span-2">
            <label className="label">
              <span className="label-text text-white">Last name:</span>
            </label>
            <input
              type="text"
              placeholder={"Doe"}
              value={lastName}
              className="input input-accent rounded-full z-40 w-full bg-opacity-30 border-0"
              onChange={e => setLastName(e.target.value)}
            />
          </div>
          <div className=" col-span-full md:col-span-2">
            <label className="label">
              <span className="label-text text-white">Email:</span>
            </label>
            <input
              required={true}
              type="email"
              placeholder={"john.doe@email.com"}
              value={email}
              className="input input-accent rounded-full z-40 w-full bg-opacity-30 border-0"
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div className=" col-span-full md:col-span-2">
            <label className="label">
              <span className="label-text text-white">Spot</span>
            </label>
            <SiteDropdown
              spotsList={spotsList}
              chosenSpot={selectedSpotName}
              setChosenSpot={setSelectedSpotName}
            />
          </div>

          <div className=" col-span-full md:col-span-4">
            <label className="label">
              <span className="label-text text-white">Message</span>
            </label>
            <textarea
              className="textarea h-24 textarea-bordered textarea-accent border-0 rounded-xl z-40 w-full bg-opacity-30"
              placeholder="Please type your message here."
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </div>

          <div className=" col-span-full md:col-span-2 flex justify-center md:justify-start items-center">
            <ReCAPTCHA
              sitekey="6LdpLVQcAAAAAA4JO4ERytv8ul7TwzPtfEMZRxZo"
              onChange={value => {
                setCaptchaValue(value)
              }}
              size="normal"
            />
          </div>
          <div className=" col-span-full md:col-span-2 flex justify-end items-center">
            <button
              type="submit"
              disabled={captchaValue === ""}
              className={`
              ${
                captchaValue === ""
                  ? " bg-gray-500 text-white opacity-50 cursor-not-allowed "
                  : " hover:bg-orbitalPrimary text-sm bg-white text-orbitalSecondary hover:text-white "
              }
              px-6 py-2
              uppercase 
              font-bold
              cursor-pointer
              rounded-full 
              transition duration-500 ease-in-out
              w-full
              md:w-1/2
              `}
            >
              SEND
            </button>
          </div>
          {formSubmitted && <Modal setFormSubmitted={() => setFormSubmitted} />}
        </div>
      </form>
      <img
        src={contactBg}
        draggable={false}
        className="absolute -bottom-0 z-0"
      />
    </div>
  )
}

function SiteDropdown({ spotsList, chosenSpot, setChosenSpot }) {
  return (
    <Menu className="relative z-50 w-full" as="div">
      <Menu.Button className="relative group input w-full justify-between  flex uppercase items-center gap-2 self-start group-hover:text-opacity-50 rounded-full">
        {chosenSpot}
        <ChevronDownIcon className="group-hover:text-orbitalAccent h-4 md:h-7" />
      </Menu.Button>
      <Menu.Items className="absolute z-50 p-8 w-full rounded-lg bg-orbitalBlack  flex flex-col gap-2 h-64 overflow-scroll">
        {spotsList?.map((spot, spotIdx) => (
          <Menu.Item key={spotIdx}>
            <button
              className={`text-opacity-90 cursor-pointer hover:text-opacity-100 text-left text-base flex justify-between items-center uppercase${
                spot.code === chosenSpot.code
                  ? " text-white text-opacity-100"
                  : " text-white"
              }`}
              onClick={() => {
                setChosenSpot(spot.city + " " + "(" + spot.code + ")")
              }}
            >
              {spot.city} ({spot.code})
              {spot.city === chosenSpot.city && (
                <CheckCircleIcon className="h-6 text-white" />
              )}
            </button>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  )
}

import React from "react"
import iconCheck from "../images/icon-check.svg"

export const HowItWorksSection = () => {
  const highlights = [
    {
      icon: <img className="h-5" src={iconCheck} />,

      title: "Location",
      text: "Choose the location for your Ground Station.",
      position: "first",
    },
    {
      icon: <img className="h-5" src={iconCheck} />,

      title: "Plan",
      text: "Set your plan including colocation, energy, connectivity and support.",
    },
    {
      icon: <img className="h-5" src={iconCheck} />,

      title: "Logistics",
      text: "Send your Antenna and devices, we take care of the customs process in each country.",
    },
    {
      icon: <img className="h-5" src={iconCheck} />,

      title: "Testing",
      text: "We install the antenna and devices and test with your team that everything works properly.",
    },
    {
      icon: <img className="h-5" src={iconCheck} />,

      title: "Data process and distribution",
      text: "Process data in our Cloud Infra and distribute with low latency across our PRESENCE Network.",
    },
  ]
  return (
    <section id="how-it-works" className="w-full mx-auto container px-4 ">
      <div className="relative z-50 w-full border border-orbitalSecondary rounded-lg h-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-16 md:gap-16 p-8 md:p-8">
        <div className="flex flex-col gap-2 justify-center md:w-2/3 col-span-full md:col-span-1">
          <h4 className="text-3xl font-bold">How it works</h4>
          <p className="text-sm font-light">
            Keep focused on your mission while we take care of everything you
            need to run your Ground Station
          </p>
        </div>
        {highlights.map(highlight => (
          <div
            key={highlight.title}
            className="flex flex-col gap-2 md:h-28 justify-center"
          >
            <div className="flex gap-2 items-center">
              {highlight.icon}
              <h5 className="font-bold capitalize text-orbitext">
                {highlight.title}
              </h5>
            </div>
            <p className="md:w-2/3 text-white text-opacity-50 text-sm">
              {highlight.text}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}

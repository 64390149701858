import React from "react"
import { Stat } from "./Stat"
import iconAntena from "../images/icon-antenna.svg"
import iconConnectivity from "../images/icon-connectivity.svg"
import iconDatacenter from "../images/icon-datacenter.svg"
import iconEnergy from "../images/icon-energy.svg"
import iconSecurity from "../images/icon-security.svg"
import iconLogistics from "../images/icon-logistic.svg"

const services = [
  {
    icon: <img className="h-10" src={iconAntena} />,
    title: "Antenna Colocation",
    text: "Groundland & rooftop locations in our datacenter network",
    position: "first",
  },
  {
    icon: <img className="h-10" src={iconConnectivity} />,

    title: "Connectivity",
    text: "Access to a high variety and low latency Internet carriers",
  },
  {
    icon: <img className="h-10" src={iconDatacenter} />,

    title: "Datacenter Services",
    text: "Colo for your RF receiver, baremetal & VPS servers",
  },
  {
    icon: <img className="h-10" src={iconEnergy} />,

    title: "Energy",
    text: "Redundant energy to protect your operations",
  },
  {
    icon: <img className="h-10" src={iconSecurity} />,

    title: "Security",
    text: "Physical and virtual security to keep your infra safe",
  },
  {
    icon: <img className="h-10" src={iconLogistics} />,

    title: "Logistic services",
    text: "We take all the importation process for you",
    position: "last",
  },
]

const StatsSection = () => (
  <section
    id="about-us"
    className="mx-auto container justify-center p-4 md:p-0"
  >
    <div className="flex flex-col md:flex-row gap-16 md:gap-8">
      <div className="flex flex-col justify-center gap-5 md:w-2/3 text-lg font-light">
        <h2 className="text-3xl font-bold">What we provide</h2>
        <p>
          Orbital Base is a all-in-one solution for your satellite antennas that
          provides everything you need to interconnect your constellations with
          the earth through spots in strategic locations across Latin America.
        </p>
        <p>
          Orbital Base allows you to scale quickly, integrating data processing,
          global connectivity, and local support staff into your operation.
        </p>
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-center items-center">
        {services.map(service => (
          <Stat
            position={service.position}
            key={service.title}
            icon={service.icon}
            title={service.title}
            text={service.text}
          />
        ))}
      </ul>
    </div>
  </section>
)

export default StatsSection

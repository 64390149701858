import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"

export const Title = () => {
  return (
    <div className="flex flex-col mt-8 md:mt-0 gap-8 md:gap-6 p-4 md:p-0 z-30 relative">
      <div className="flex flex-col gap-5 md:gap-6 text-left">
        <h1 className="flex flex-col md:w-full text-4xl md:text-5xl text-white font-bold">
          Providing the best spots to link satellite constellations with the
          earth
        </h1>
        <p className="flex flex-col font-extralight text-base md:text-xl md:w-4/5">
          The race to conquer space has started. Get focused on your mission
          while we keep your antennas running safe in key spots across Latin
          America.
        </p>
      </div>

      <div className="flex justify-start">
        <AnchorLink
          to={"/#contact-us"}
          className="w-1/2 flex justify-center items-center md:w-3/12 uppercase hover:bg-orbitalPrimary text-sm bg-white font-bold text-orbitalSecondary hover:text-white border-white h-10 rounded-full focus:outline-none no-animation transition duration-500 ease-in-out"
        >
          Contact us
        </AnchorLink>
      </div>
    </div>
  )
}

import React, { useEffect, useState, useContext, useMemo } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5map from "@amcharts/amcharts5/map"
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import { AppContext } from "./Provider"

export default function Globe({ sites }) {
  const { state, setState, setSelectedSpot } = useContext(AppContext)
  const [chartRef, setChartRef] = useState(null)

  const handleStateChange = countryName => {
    let cities = [sites[0].sites.concat(sites[1].sites).concat(sites[2].sites)]
    let selectedCity = cities[0].filter(city => {
      return city.name === countryName
    })
    setState(selectedCity[0])
    setSelectedSpot(selectedCity[0].spots[0])
  }

  useEffect(() => {
    //Create root element
    let root = am5.Root.new("chartdiv")
    // Set themes
    root.setThemes([am5themes_Animated.new(root)])

    // Create the map chart
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoOrthographic(),
        panX: "rotateX",
        panY: "rotateY",
        rotationX: 80,
        rotationY: 5,
        rotationZ: 5,
        draggable: false,
        resizable: false,
        maxZoomLevel: 1,
        opacity: 1,
      })
    )

    setChartRef(chart)

    // Create series for background fill
    let backgroundSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {})
    )

    //Background properties
    backgroundSeries.mapPolygons.template.setAll({
      fill: am5.color("#010019"),
      strokeOpacity: 0,
    })

    backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180),
    })

    // Create main polygon series for countries
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        fill: am5.color("#51349A"),
        stroke: am5.color("#36E8DA"),
        fillOpacity: 1,
      })
    )

    // polygonSeries.mapPolygons.template.setAll({
    //   // tooltipText: "{name}",
    //   toggleKey: "active",
    //   fill: am5.color("#11C4D4"),
    //   interactive: true,
    // })

    // //Hover for countries
    // polygonSeries.mapPolygons.template.states.create("hover", {
    //   fill: am5.color("#11C4D4"),
    //   opacity: 1,
    // })

    // polygonSeries.mapPolygons.template.states.create("active", {
    //   fill: am5.color("#11C4D4"),
    //   opacity: 1,
    // })

    // Rotate animation
    // chart.animate({
    //   key: "rotationX",
    //   from: 60,
    //   to: 180,
    //   duration: 120000,
    //   loops: Infinity,
    // })

    // Create point series for markers
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-point-series/
    let pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}))

    function getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
    }

    let inactivePointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {})
    )

    pointSeries.bullets.push(function () {
      let container = am5.Container.new(root, {})
      let circle = container.children.push(
        am5.Circle.new(root, {
          radius: 4,
          tooltipY: 0,
          fill: am5.color("#36E8DA"),
        })
      )

      let marker = container.children.push(
        am5.Circle.new(root, {
          radius: 6,
          tooltipY: 0,
          fill: am5.color("#36E8DA"),
          stroke: am5.color("#0A0F4F"),
          strokeOpacity: 1,
          tooltipText: "{name} - ACTIVE",
        })
      )

      marker.events.on("click", function (ev) {
        handleStateChange(ev.target.dataItem.dataContext.name)
      })

      marker.events.on("pointerover", function (ev) {
        document.body.style.cursor = "pointer"
      })

      marker.events.on("pointerout", function (ev) {
        document.body.style.cursor = "default"
      })

      circle.animate({
        key: "scale",
        from: 1,
        to: getRandomInt(5, 20),
        duration: 4000,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      })
      circle.animate({
        key: "opacity",
        from: 1,
        to: 0,
        duration: 4000,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      })

      return am5.Bullet.new(root, {
        sprite: container,
      })
    })

    inactivePointSeries.bullets.push(function () {
      let container = am5.Container.new(root, {})

      let marker = container.children.push(
        am5.Circle.new(root, {
          radius: 6,
          tooltipY: 0,
          fill: am5.color("#808080"),
          stroke: am5.color("#0A0F4F"),
          strokeOpacity: 1,
          tooltipText: "{name} - COMING SOON",
        })
      )

      return am5.Bullet.new(root, {
        sprite: container,
      })
    })

    //Add cities (Sites) to map
    let cities = [sites[0].sites.concat(sites[1].sites).concat(sites[2].sites)]

    cities[0].map(city => {
      addCity(
        city.longitude,
        city.latitude,
        city.name,
        city.active,
        city.countryCode
      )
    })

    function addCity(longitude, latitude, name, isActive, countryCode) {
      isActive &&
        pointSeries.data.push({
          geometry: { type: "Point", coordinates: [longitude, latitude] },
          name: name,
          country: countryCode,
        })
      !isActive &&
        inactivePointSeries.data.push({
          geometry: { type: "Point", coordinates: [longitude, latitude] },
          name: name,
          country: countryCode,
        })
    }

    chart.animate({
      key: "rotationX",
      to: -state.geometry.coordinates[1],
      duration: 1500,
      easing: am5.ease.inOut(am5.ease.cubic),
    })
    chart.animate({
      key: "rotationY",
      to: -state.geometry.coordinates[0],
      duration: 1500,
      easing: am5.ease.inOut(am5.ease.cubic),
    })

    // Make stuff animate on load
    chart.appear(1000, 100)
  }, [])

  useEffect(() => {
    if (chartRef) {
      chartRef.animate({
        key: "rotationX",
        to: -state.geometry.coordinates[1],
        duration: 1500,
        easing: am5.ease.inOut(am5.ease.cubic),
      })
      chartRef.animate({
        key: "rotationY",
        to: -state.geometry.coordinates[0],
        duration: 1500,
        easing: am5.ease.inOut(am5.ease.cubic),
      })
    }
  }, [state.geometry.coordinates])

  return useMemo(() => <div id="chartdiv" className="w-full h-full" />, [])
}

import React from "react"
import Globe from "./Globe"
import { SiteInformation } from "./SiteInformation"
import sateltiteSvg from "../images/image-satellite.png"

export const SpotsSection = ({ sites }) => {
  return (
    <div className="relative flex flex-col">
      <section className="flex z-20 relative flex-col md:flex-row mx-auto container justify-between items-center w-full">
        <div className="md:w-1/2">
          <SiteInformation stationsList={Object.values(sites)} />
        </div>
        <div className="w-1/3 flex items-center justify-center invisible md:visible md:h-60vh">
          <Globe sites={Object.values(sites)} />
          <div className="flex absolute gap-16 top-5 2xl:top-20 rounded-lg bg-black justify-around items-center bg-opacity-50 px-5 h-10">
            <div className="flex gap-2 items-center">
              {" "}
              <div className="w-3 h-3 bg-orbitalAccent rounded-full"></div>{" "}
              Active
            </div>
            <div className="flex gap-2 items-center ">
              {" "}
              <div className="w-3 h-3 bg-gray-500 rounded-full"></div> Coming
              soon
            </div>
          </div>
        </div>
      </section>
      <img
        src={sateltiteSvg}
        draggable={false}
        className="absolute z-0 invisible md:visible"
      />
    </div>
  )
}

import React from "react"

const SpotSectionTitle = () => {
  return (
    <div id="spots" className="flex flex-col text-orbitext">
      <h3 className="text-4xl font-bold">Spots</h3>
      <h4 className="text-2xl font-thin w-max pb-2">
        Throughout Latin America
      </h4>
      <p className="py-4 text-white md:w-2/3">
        We have developed <b>PRESENCE</b> a network of datacenters and carriers
        to reach the best latencies in the region. If you need to deploy your
        Ground Stations in Latin America we are the right place.
      </p>
      <p>Choose the location for your antennas:</p>
    </div>
  )
}

export default SpotSectionTitle

import React, { useContext } from "react"
import { AppContext } from "./Provider"
import { SiteBadge } from "./SiteBadge"

export const SiteInformation = ({ stationsList }) => {
  const {
    state,
    setState,
    selectedSpot,
    setSelectedSpot,
    selectedSpotName,
    setSelectedSpotName,
  } = useContext(AppContext)

  return (
    <SiteBadge
      site={state}
      selectedSpot={selectedSpot}
      setSelectedSpot={setSelectedSpot}
      stationsList={stationsList}
      setContextState={setState}
      selectedSpotName={selectedSpotName}
      setSelectedSpotName={setSelectedSpotName}
    />
  )
}

import React from "react"
import { Title } from "./Title"
import sateliteSvg from "../images/image-hero.png"

export const HeroSection = () => {
  return (
    <section
      id="hero"
      className="md:h-80vh 2xl:h-50vh mx-auto container md:items-center flex"
    >
      <div className="md:w-2/3">
        <Title />
      </div>
      <img
        src={sateliteSvg}
        draggable={false}
        className="absolute z-0 -right-50 opacity-70 md:opacity-100  md:top-20 md:-right-40 2xl:right-0"
      />
    </section>
  )
}
